import styled from 'styled-components';
import circles from './assets/circles.svg';

export const HeroSection = styled.div`
  max-height: 1200px;
  height: 85vh;
  width: 100vw;
  background-image: url(${circles});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`;

HeroSection.displayName = 'HeroSection';
