import styled, {css} from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HTML5Logo from '../../../../display-components/logos/HTML5Logo';
import CSS3Logo from '../../../../display-components/logos/CSS3Logo';
import MUILogo from '../../../../display-components/logos/MUILogo';
import JavascriptLogo from '../../../../display-components/logos/JavascriptLogo';
import MeteorLogo from '../../../../display-components/logos/MeteorLogo';
import MongoDBLogo from '../../../../display-components/logos/MongoDBLogo';
import NodeLogo from '../../../../display-components/logos/NodeLogo';
import ReactLogo from '../../../../display-components/logos/ReactLogo';

const iconCss = css`
  font-size: 75px;
  margin: 30px 100px;
  ${({theme}) => theme.breakpoints.down('lg')} {
    margin: 30px 90px;
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    margin: 30px 95px;
  }
  ${({theme}) => theme.breakpoints.down('sm')} {
    margin: 30px 40px;
  }
`;

export const Container = styled(Grid)`
  padding: 100px 0;
  background-color: ${({theme}) => theme.palette.grey[100]};
  text-align: center;
  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: 50px 0;
  }
`;

Container.displayName = 'Container';

export const HeadingContainer = styled(Grid)``;

HeadingContainer.displayName = 'HeadingContainer';

export const Heading = styled(Typography)`
  margin-bottom: 30px;
`;

Heading.displayName = 'Heading';

export const SkillsContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
`;

SkillsContainer.displayName = 'SkillsContainer';

export const ReactIcon = styled(ReactLogo)`
  ${iconCss}
`;

ReactIcon.displayName = 'ReactIcon';

export const JavascriptIcon = styled(JavascriptLogo)`
  ${iconCss}
`;

JavascriptIcon.displayName = 'JavascriptIcon';

export const NodeIcon = styled(NodeLogo)`
  ${iconCss}
`;

NodeIcon.displayName = 'NodeIcon';

export const MongoDBIcon = styled(MongoDBLogo)`
  ${iconCss}
`;

MongoDBIcon.displayName = 'MongoDBIcon';

export const MUIIcon = styled(MUILogo)`
  ${iconCss}
`;

MUIIcon.displayName = 'MUIIcon';

export const MeteorIcon = styled(MeteorLogo)`
  ${iconCss}
`;

MeteorIcon.displayName = 'MeteorIcon';

export const HTML5Icon = styled(HTML5Logo)`
  ${iconCss}
`;

HTML5Icon.displayName = 'HTML5Icon';

export const CSS3Icon = styled(CSS3Logo)`
  ${iconCss}
`;

CSS3Icon.displayName = 'CSS3Icon';
