import React from 'react';
import {useInView} from 'react-intersection-observer';
import Fade from '@mui/material/Fade';
import resume from '../../../../assets/resume.pdf';
import {
  Background,
  Content,
  Heading,
  Subheading,
  DownloadButton,
} from './styles';

// const useStyles = makeStyles(theme => ({
//   marginBottom: {
//     marginBottom: 10,
//   },
//   subheading: {
//     marginBottom: 10,
//     textTransform: 'uppercase',
//     letterSpacing: 2,
//   },
//   button: {
//     marginTop: 20,
//     '&:focus, &:hover': {
//       color: theme.palette.grey[100],
//       textDecoration: 'none',
//     },
//   },
// }));

const Hero = () => {
  const {ref: heroRef, inView: heroInView} = useInView({triggerOnce: true});

  return (
    <Background
      container
      justifyContent="center"
      direction="row"
      id="hero-section"
    >
      <Fade in={heroInView} timeout={2000}>
        <Content item xs={10} sm={8} ref={heroRef}>
          <Heading variant="h2">Hi, I'm</Heading>
          <Heading variant="h1">TRISTRAM</Heading>
          <Subheading variant="h4">Software Engineer</Subheading>
          <DownloadButton
            href={resume}
            download="tristram_jones_resume"
            target="_blank"
          >
            Download Resume
          </DownloadButton>
        </Content>
      </Fade>
    </Background>
  );
};

export default Hero;
