import React from 'react';
import {useInView} from 'react-intersection-observer';
import Grid from '@mui/material/Grid';
import Fade from '../../../../display-components/Fade';
import {
  Container,
  HeadingContainer,
  Heading,
  SkillsContainer,
  ReactIcon,
  JavascriptIcon,
  NodeIcon,
  MongoDBIcon,
  MUIIcon,
  MeteorIcon,
  HTML5Icon,
  CSS3Icon,
} from './styles';

const SkillsSection = props => {
  const classes = {};

  const {ref: titleRef, inView: titleInView} = useInView({triggerOnce: true});

  return (
    <Container container justifyContent="center" id="skills-section">
      <HeadingContainer item xs={12}>
        <Fade in={titleInView}>
          <Heading variant="h3" ref={titleRef}>
            My Tools
          </Heading>
        </Fade>
      </HeadingContainer>
      <SkillsContainer item xs={12}>
        <ReactIcon />
        <JavascriptIcon />
        <NodeIcon />
        <MongoDBIcon />
        <MUIIcon />
        <MeteorIcon />
        <HTML5Icon />
        <CSS3Icon />
      </SkillsContainer>
    </Container>
  );
};

export default SkillsSection;
