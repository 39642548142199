import styled, {css} from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {default as MuiIconButton} from '@mui/material/IconButton';
import {default as GithubLogo} from '../icons/GithubIcon';
import {default as LinkedInLogo} from '../icons/LinkedInIcon';

const iconStyles = css`
  color: ${({theme}) => theme.palette.grey[100]};
  margin-left: 8px;
`;

export const Container = styled(Grid)``;

Container.displayName = 'Container';

export const RowContainer = styled(Grid)`
  max-width: 920px;
  padding: 50px 0;
`;

RowContainer.displayName = 'RowContainer';

export const Row = styled(Grid)`
  justify-content: space-between;
  ${({theme}) => theme.breakpoints.down('sm')} {
    align-items: center;
    flex-direction: column;
    gap: ${({theme}) => theme.spacing(2)};
  }
`;

Row.displayName = 'Row';

export const Column = styled(Grid)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

Column.displayName = 'Column';

export const Copyright = styled(Typography)``;

Copyright.displayName = 'Copyright';

export const IconButton = styled(MuiIconButton)``;

IconButton.displayName = 'IconButton';

export const GithubIcon = styled(GithubLogo)`
  ${iconStyles}
`;

GithubIcon.displayName = 'GithubIcon';

export const LinkedInIcon = styled(LinkedInLogo)`
  ${iconStyles}
`;

LinkedInIcon.displayName = 'LinkedInIcon';
