import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ProjectCard from '../../../../display-components/ProjectCard';

export const Container = styled(Grid)``;

Container.displayName = 'Container';

export const Content = styled(Grid)`
  padding: 100px 0;
  text-align: center;
  max-width: 920px;
`;

Content.displayName = 'Content';

export const Heading = styled(Typography)`
  color: ${({theme}) => theme.palette.grey[100]};
  margin-bottom: 30px;
`;

Heading.displayName = 'Heading';

export const Projects = styled(Grid)``;

Projects.displayName = 'Projects';

export const Project = styled(ProjectCard)``;

Project.displayName = 'Project';
