import React from 'react';
import {useInView} from 'react-intersection-observer';
import Fade from '../../../../display-components/Fade';
import {
  ContactButton,
  Container,
  Content,
  Description,
  Heading,
} from './styles';

const About = ({openContactModal}) => {
  const {ref: titleRef, inView: titleInView} = useInView({triggerOnce: true});
  const {ref: buttonRef, inView: buttonInView} = useInView({triggerOnce: true});

  return (
    <Container container justifyContent="center" id="about-section">
      <Content item xs={10} sm={8} md={6}>
        <Fade in={titleInView}>
          <Heading variant="h3" ref={titleRef}>
            About Me
          </Heading>
        </Fade>
        <Description variant="body1">
          I'm a software engineer that has years of experience building web apps
          in full-stack JavaScript. That being said, I get the most energized
          when I'm working on the frontend. I love coding most when I'm building
          a beautiful, pixel-perfect UI. I bring strong skills in team-building
          and personnel management that help foster empathy and a positive team
          culture.
        </Description>
        <Fade in={buttonInView}>
          <ContactButton onClick={openContactModal} ref={buttonRef}>
            Get in Touch
          </ContactButton>
        </Fade>
      </Content>
    </Container>
  );
};

export default About;
