import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import useWindowSize from '../../hooks/useWindowSize';
import ContactForm from '../ContactForm';
import {ContactDialog, Paper, CloseButton, CloseIcon, Heading} from './styles';

const ContactModal = ({open, closeContactModal}) => {
  const [sendSuccess, setSendSuccess] = useState(false);
  const windowSize = useWindowSize();
  const theme = useTheme();
  console.log(`theme:`, theme);

  const messageSent = event => {
    setSendSuccess(true);
    setTimeout(closeContactModal, 1500);
  };

  return (
    <ContactDialog
      open={open}
      onClose={closeContactModal}
      fullWidth
      fullScreen={windowSize.width < theme.breakpoints.values.sm}
      maxWidth={'md'}
      PaperComponent={Paper}
    >
      <CloseButton onClick={closeContactModal} size="large">
        <CloseIcon />
      </CloseButton>
      <Heading variant="h3">Let's Connect!</Heading>
      {sendSuccess ? (
        <Heading variant="body1">Your message was sent successfully!</Heading>
      ) : (
        <ContactForm messageSent={messageSent} />
      )}
    </ContactDialog>
  );
};

ContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeContactModal: PropTypes.func.isRequired,
};

export default ContactModal;
