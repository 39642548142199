import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import usePrevious from '../../hooks/usePrevious';
import scrollIntoView from 'scroll-into-view';
import {NavTabs, NavTab} from './styles';

const Navbar = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const prevPath = usePrevious(location.pathname);

  useEffect(() => setLocationValue(), []);

  useEffect(() => {
    if (prevPath !== location.pathname) {
      setLocationValue();
    }
  }, [location.pathname]);

  const setLocationValue = () => {
    let selector, value;
    switch (location.pathname) {
      case '/':
        value = 0;
        selector = '#navbar';
        break;
      case '/skills':
        value = 1;
        selector = '#skills-section';
        break;
      case '/projects':
        value = 2;
        selector = '#projects-section';
        break;
      case '/about':
        value = 3;
        selector = '#about-section';
        break;
      default:
        value = 0;
        selector = '#navbar';
        break;
    }
    setValue(value);
    const section = document.querySelector(selector);
    section && scrollIntoView(section);
  };

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <NavTabs
      id="navbar"
      value={value}
      onChange={handleChange}
      textColor="primary"
    >
      <NavTab component={Link} label="Home" to="/" />
      <NavTab component={Link} label="Skills" to="/skills" />
      <NavTab component={Link} label="Projects" to="/projects" />
      <NavTab component={Link} label="About" to="/about" />
    </NavTabs>
  );
};

Navbar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default Navbar;
