import React from 'react';
import {useInView} from 'react-intersection-observer';
import Fade from '../../../../display-components/Fade';
import projectData from '../../assets/projectData';
import {Container, Content, Heading, Projects, Project} from './styles';

const ProjectsSection = () => {
  const {ref: titleRef, inView: titleInView} = useInView({triggerOnce: true});

  return (
    <Container container justifyContent="center" id="projects-section">
      <Content item lg={8} xs={10}>
        <Fade in={titleInView}>
          <Heading variant="h3" ref={titleRef}>
            My Projects
          </Heading>
        </Fade>
        <Projects container>
          {projectData.map(project => {
            return (
              <Project
                key={project.title}
                title={project.title}
                imgSrc={project.imgSrc}
                herokuURL={project.herokuURL}
                text={project.text}
                demoLinkURL={project.demoLinkURL}
              />
            );
          })}
        </Projects>
      </Content>
    </Container>
  );
};

export default ProjectsSection;
