import styled from 'styled-components';
import {Tabs, Tab} from '@mui/material';

const gradient = ({theme}) =>
  `linear-gradient(110deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`;

export const NavTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    margin: 4px 4px 0 0;
    justify-content: flex-end;
    ${({theme}) => theme.breakpoints.down('sm')} {
      justify-content: center;
      margin: 4px 0 0 0;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
`;

NavTabs.displayName = 'NavTabs';

export const NavTab = styled(Tab)`
  color: ${({theme}) => theme.palette.grey[100]};
  margin: 0 8px;
  position: relative;
  min-width: 80px;
  &::before {
    height: 3px;
    content: '';
    position: absolute;
    background: ${gradient};
    width: 0%;
    bottom: 6px;
    transition: 300ms;
  }
  &:hover:before {
    width: 100%;
  }
  ${({theme}) => theme.breakpoints.down('sm')} {
    margin: 0;
  }

  &.Mui-selected {
    &::before {
      height: 3px;
      content: '';
      position: absolute;
      background: ${gradient};
      width: 100%;
      bottom: 6px;
    }
  }
`;

NavTab.displayName = 'NavTab';
