import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function CSS3Logo(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="102.3716mm"
      height="144.49777mm"
      viewBox="0 0 362.73401 511.99998"
      id="svg3476"
      version="1.1"
      {...props}
    >
      <defs id="defs3478" />
      <g id="layer1" transform="translate(-193.633,-276.3622)">
        <g id="g3013" transform="translate(119,276.3622)">
          <polygon
            id="polygon2989"
            points="437.367,100.62 404.321,470.819 255.778,512 107.644,470.877 74.633,100.62 "
            style={{fill: '#264de4'}}
          />
          <polygon
            id="polygon2991"
            points="376.03,447.246 404.27,130.894 256,130.894 256,480.523 "
            style={{fill: '#2965f1'}}
          />
          <polygon
            id="polygon2993"
            points="150.31,268.217 154.38,313.627 256,313.627 256,268.217 "
            style={{fill: '#ebebeb'}}
          />
          <polygon
            id="polygon2995"
            points="256,176.305 255.843,176.305 142.132,176.305 146.26,221.716 256,221.716 "
            style={{fill: '#ebebeb'}}
          />
          <polygon
            id="polygon2997"
            points="256,433.399 256,386.153 255.801,386.206 205.227,372.55 201.994,336.333 177.419,336.333 156.409,336.333 162.771,407.634 255.791,433.457 "
            style={{fill: '#ebebeb'}}
          />
          <path
            id="path2999"
            d="m 160,0 55,0 0,23 -32,0 0,23 32,0 0,23 -55,0 z"
          />
          <path
            id="path3001"
            d="m 226,0 55,0 0,20 -32,0 0,4 32,0 0,46 -55,0 0,-21 32,0 0,-4 -32,0 z"
          />
          <path
            id="path3003"
            d="m 292,0 55,0 0,20 -32,0 0,4 32,0 0,46 -55,0 0,-21 32,0 0,-4 -32,0 z"
          />
          <polygon
            id="polygon3005"
            points="311.761,313.627 306.49,372.521 255.843,386.191 255.843,433.435 348.937,407.634 349.62,399.962 360.291,280.411 361.399,268.217 369.597,176.305 255.843,176.305 255.843,221.716 319.831,221.716 315.699,268.217 255.843,268.217 255.843,313.627 "
            style={{fill: '#ffffff'}}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default CSS3Logo;
