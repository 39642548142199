import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const Background = styled(Grid)`
  background-image: linear-gradient(to bottom, transparent 30%, black 100%);
  height: 100%;
  overflow: hidden;
`;

Background.displayName = 'Background';

export const Content = styled(Grid)`
  z-index: 1000;
  padding: 5% 8% 8% 8%;
  margin: auto;
`;

Content.displayName = 'Content';

export const Heading = styled(Typography)`
  margin-bottom: 10px;
`;

Heading.displayName = 'Heading';

export const Subheading = styled(Typography)`
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

Subheading.displayName = 'Subheading';

export const DownloadButton = styled(Button)`
  margin-top: 20px;
  &:focus,
  &:hover {
    color: ${({theme}) => theme.palette.grey[100]};
    text-decoration: none;
  }
`;

DownloadButton.displayName = 'DownloadButton';
