import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function HTML5Logo(props) {
  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      {...props}
    >
      <g>
        <g>
          <g>
            <path d="M108.382,0h23.077v22.8h21.11V0h23.078v69.044H152.57v-23.12h-21.11v23.12h-23.077L108.382,0L108.382,0z" />
            <path d="M205.994,22.896h-20.316V0h63.72v22.896h-20.325v46.148h-23.078L205.994,22.896L205.994,22.896z" />
            <path
              d="M259.511,0h24.063l14.802,24.26L313.163,0h24.072v69.044h-22.982V34.822l-15.877,24.549h-0.397l-15.888-24.549v34.222
            h-22.58V0z"
            />
            <path d="M348.72,0h23.084v46.222h32.453v22.822H348.72V0z" />
          </g>
        </g>
      </g>
      <g id="Guides_to_delete">
        <g>
          <polygon
            fill="#E34E26"
            points="107.125,470.727 74.012,99.297 437.703,99.297 404.549,470.755 255.769,512 		"
          />
          <polygon
            fill="#EF662A"
            points="255.857,129.657 255.857,480.449 376.164,447.101 404.51,129.657 		"
          />
          <polygon
            fill="#FFFFFF"
            points="141.761,175.217 154.041,312.978 311.936,312.978 306.637,372.066 255.799,385.811 
          205.057,372.085 201.664,334.146 155.937,334.146 162.476,407.282 255.787,433.199 349.219,407.282 361.744,267.429 
          195.706,267.429 191.538,220.777 365.732,220.777 365.873,220.777 369.953,175.217 		"
          />
          <g>
            <polygon
              fill="#EBEBEB"
              points="255.857,175.217 141.761,175.217 154.041,312.978 255.857,312.978 255.857,267.429 
            195.706,267.429 191.538,220.777 255.857,220.777 			"
            />
            <polygon
              fill="#EBEBEB"
              points="255.857,385.791 255.799,385.811 205.057,372.085 201.664,334.146 155.937,334.146 
            162.476,407.282 255.787,433.199 255.857,433.179 			"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default HTML5Logo;
