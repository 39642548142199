import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function ArrowUpIcon(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="2 0 28 28" {...props}>
      <g>
        <rect fill="none" id="canvas_background" y="-1" x="-1" />
      </g>
      <g>
        <path
          transform="rotate(180 16.49449920654297,17.000621795654297) "
          id="svg_1"
          fill="currentColor"
          d="m14.77,23.795l-9.585,-9.585c-0.879,-0.879 -0.879,-2.317 0,-3.195l0.8,-0.801c0.877,-0.878 2.316,-0.878 3.194,0l7.315,7.315l7.316,-7.315c0.878,-0.878 2.317,-0.878 3.194,0l0.8,0.801c0.879,0.878 0.879,2.316 0,3.195l-9.587,9.585c-0.471,0.472 -1.104,0.682 -1.723,0.647c-0.619,0.035 -1.251,-0.175 -1.724,-0.647z"
        />
      </g>
    </SvgIcon>
  );
}

export default ArrowUpIcon;
