import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const Container = styled(Grid)`
  padding: 100px 0;
  background-color: ${({theme}) => theme.palette.grey[100]};
  text-align: center;
`;

Container.displayName = 'Container';

export const Content = styled(Grid)`
  max-width: 920px;
`;

Content.displayName = 'Content';

export const Heading = styled(Typography)``;

Heading.displayName = 'Heading';

export const Description = styled(Typography)`
  padding: 20px 0;
  margin-bottom: 10px;
`;

Description.displayName = 'Description';

export const ContactButton = styled(Button)``;

ContactButton.displayName = 'ContactButton';
