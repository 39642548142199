import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useInView} from 'react-intersection-observer';
import Fade from '../Fade';
import {
  Container,
  Card,
  CardImage,
  CardImageOverlay,
  Divider,
  ProjectLink,
  Title,
  Subtitle,
  Body,
  Description,
  Actions,
  DemoLink,
  DemoLinkText,
  AccordionButton,
  ArrowUp,
  ArrowDown,
} from './styles';

const ProjectCard = ({title, imgSrc, text, herokuURL, demoLinkURL}) => {
  const [expanded, setExpanded] = useState(false);

  const {ref: cardRef, inView: cardInView} = useInView({triggerOnce: true});

  const toggleAccordion = event => {
    setExpanded(!expanded);
  };

  return (
    <Container item md={4} s={8}>
      <Fade in={cardInView}>
        <Card raised ref={cardRef}>
          <CardImage image={imgSrc} />
          <ProjectLink
            target="_blank"
            rel="noopener noreferrer"
            href={herokuURL}
          >
            <CardImageOverlay>
              <Title variant="h6">{title[0]}</Title>
              <Divider />
              <Subtitle variant="h3">{title}</Subtitle>
            </CardImageOverlay>
          </ProjectLink>
          <Body>
            <Description variant="body1" $expanded={expanded}>
              {text}
            </Description>
            <Actions $expanded={expanded}>
              <DemoLink
                target="_blank"
                rel="noopener noreferrer"
                href={demoLinkURL}
              >
                <DemoLinkText variant="button">SEE A DEMO</DemoLinkText>
              </DemoLink>
              <AccordionButton onClick={toggleAccordion}>
                {expanded ? <ArrowUp /> : <ArrowDown />}
              </AccordionButton>
            </Actions>
          </Body>
        </Card>
      </Fade>
    </Container>
  );
};

ProjectCard.propTypes = {
  ready: PropTypes.bool,
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  herokuURL: PropTypes.string,
  text: PropTypes.string,
  demoLinkURL: PropTypes.string,
};

export default ProjectCard;
