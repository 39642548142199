import React, {useState} from 'react';
import Footer from '../../display-components/Footer';
import ContactModal from '../../display-components/ContactModal';
import Skills from './components/Skills';
import Projects from './components/Projects';
import About from './components/About';

const HomePage = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  const openContactModal = () => {
    setShowContactModal(true);
  };

  const closeContactModal = () => {
    setShowContactModal(false);
  };

  return (
    <>
      <Skills />
      <Projects />
      <About openContactModal={openContactModal} />
      <Footer />
      <ContactModal
        open={showContactModal}
        closeContactModal={closeContactModal}
      />
    </>
  );
};

export default HomePage;
