import React, {useState} from 'react';
import {Form, Input, SendButton} from './styles';

const ContactForm = ({messageSent}) => {
  const [formState, setFormState] = useState({
    dirty: false,
    name: '',
    email: '',
    message: '',
  });
  const {dirty, name, email, message} = formState;

  function handleChange(event) {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    const contactForm = document.getElementById('contactForm');
    const service_id = 'tristramjones';
    const template_id = 'tristram_portfolio';
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Sending...';

    if (name !== '' && email !== '' && message !== '') {
      setFormState({...formState, dirty: false});
      window.emailjs.sendForm(service_id, template_id, contactForm).then(
        () => {
          messageSent();
          submitButton.innerText = 'Send Message';
        },
        err => {
          alert('Send email failed!\r\n Response:\n ' + JSON.stringify(err));
          submitButton.innerText = 'Send Message';
        },
      );
    } else {
      setFormState({...formState, dirty: true});
      submitButton.innerText = 'Send Message';
    }

    return false;
  }

  return (
    <Form id="contactForm" onSubmit={handleFormSubmit}>
      <Input
        variant="outlined"
        onChange={handleChange}
        placeholder="Name"
        name="name"
        value={name}
        inputProps={{maxLength: 60}}
        error={name === '' && dirty}
        helperText={name === '' && dirty ? '*This field is required' : ' '}
      />
      <Input
        variant="outlined"
        onChange={handleChange}
        placeholder="Email"
        name="email"
        value={email}
        inputProps={{maxLength: 60}}
        error={email === '' && dirty}
        helperText={email === '' && dirty ? '*This field is required' : ' '}
      />
      <Input
        variant="outlined"
        onChange={handleChange}
        placeholder="Message"
        name="message"
        value={message}
        multiline
        rows="4"
        inputProps={{maxLength: 350}}
        error={message === '' && dirty}
        helperText={message === '' && dirty ? '*This field is required' : ' '}
      />
      <SendButton onClick={handleFormSubmit} id="submitButton">
        Send Message
      </SendButton>
    </Form>
  );
};

export default ContactForm;
