import React from 'react';
import {
  Container,
  RowContainer,
  Row,
  Column,
  Copyright,
  IconButton,
  GithubIcon,
  LinkedInIcon,
} from './styles';

const Footer = () => {
  const classes = {};

  return (
    <Container container justifyContent="center">
      <RowContainer item lg={8} xs={10}>
        <Row container>
          <Column item>
            <Copyright variant="body2">© 2021 Tristram Jones</Copyright>
          </Column>
          <Column item>
            <IconButton
              href="https://github.com/tristramjones"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubIcon className={classes.icon} />
            </IconButton>
            <IconButton
              href="https://linkedin.com/in/tristramjones"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon className={classes.icon} />
            </IconButton>
          </Column>
        </Row>
      </RowContainer>
    </Container>
  );
};

export default Footer;
