import bucketList from './bucket-list.png';
import myWeather from './weather-app.png';
import zombieRoad from './zombie-road.png';

const projectData = [
  {
    title: 'BucketList',
    herokuURL: 'https://bucketlistclient.herokuapp.com/',
    imgSrc: bucketList,
    text: 'BucketList is an interactive travel and adventure planner for those with wanderlust. BucketList uses Leaflet and React-Leaflet node packages, coupled with React.js and CSS for the front-end components. BucketList is configured with Rails and Redux.js to store information on user travel goals, preferences, etc. User login is secured using OAuth to enable user interaction with their personal, private bucket list. Users have access to full CRUD capabilities on map markers, enabling user to flexibly control their travel plans.',
    demoLinkURL: 'https://www.loom.com/share/f4eb7bec4cb64fedbe47596e8714456a',
  },
  {
    title: 'MyWeather',
    herokuURL: 'https://weather-app-10.herokuapp.com/weather',
    imgSrc: myWeather,
    text: 'MyWeather is a current and 5-day weather forecast app for people on the go. The app employs a custom accordion feature to display 5-day weather forecast through conditional rendering in React. MyWeather utilizes custom CSS to style active and hover states for all interactive features (buttons, logo, accordion). This app integrates a third party API (OpenWeatherMap) to return city details tailored to each user query.',
    demoLinkURL: 'https://www.loom.com/share/ed5bd5a4021f4fa7b1864b57840d9fd0',
  },
  {
    title: 'ZombieRoad',
    herokuURL: 'https://zombieroad.herokuapp.com/index.html',
    imgSrc: zombieRoad,
    text: "Inspired by 90's arcade games, ZombieRoad is a single player side scrolling game; guide a puppy down ZombieRoad and avoid getting infected! Built back-end APIs using Ruby on Rails and the MVC pattern to store user names and game scores. Designed frontend using vanilla JavaScript and the P5 library for side-scrolling animations. All avatar animations were created by looping through a series of 8 static images, enhancing the game's authentic, arcade feel.",
    demoLinkURL: 'https://www.loom.com/share/4fbb7a7a8ed447b3a4b533d7bf98698d',
  },
];

export default projectData;
