import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {ThemeProvider} from 'styled-components';
import {theme} from './theme';
import Navbar from './display-components/Navbar';
import Hero from './pages/Home/components/Hero';
import {HeroSection} from './styles';
import HomePage from './pages/Home';

const App = () => (
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <HeroSection>
            <Navbar />
            <Hero />
          </HeroSection>
          <HomePage />
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);

export default App;
