import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export const Form = styled(FormControl)`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${({theme}) => theme.spacing(4)};
`;

Form.displayName = 'Form';

export const Input = styled(TextField)`
  font-size: 15px;
  width: 100%;

  & .MuiInputBase-root {
    background: ${({theme}) => theme.palette.grey[100]};
  }
  .MuiInputBase-input {
    font-size: 15px;
    width: 100%;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

Input.displayName = 'Input';

export const SendButton = styled(Button)`
  margin-top: 15px;
  ${({theme}) => theme.breakpoints.down('sm')} {
    align-self: center;
  }
`;

SendButton.displayName = 'SendButton';
