import styled, {css} from 'styled-components';
import Grid from '@mui/material/Grid';
import {default as MuiCard} from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';

const arrowStyles = css`
  font-size: 22px;
  color: ${({theme}) => theme.palette.grey[100]};
`;

export const Container = styled(Grid)``;

Container.displayName = 'Container';

export const Card = styled(MuiCard)`
  margin: 25px 12px;
  overflow: visible;
  position: relative;
`;

Card.displayName = 'Card';

export const CardImage = styled(CardMedia)`
  height: 200px;
  border-radius: 4px 4px 0 0;
`;

CardImage.displayName = 'CardImage';

export const CardImageOverlay = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 4px 4px 0 0;
  width: 100%;
  height: 200px;
  opacity: 0.9;
  background: ${({theme}) =>
    `linear-gradient(100deg, ${theme.palette.primary.main} 25%, ${theme.palette.secondary.main} 130%)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

CardImageOverlay.displayName = 'CardImageOverlay';

export const ProjectLink = styled.a``;

ProjectLink.displayName = 'ProjectLink';

export const Divider = styled.hr`
  background: ${({theme}) => theme.palette.grey[100]};
  width: 70px;
  height: 5px;
  border: none;
`;

Divider.displayName = 'Divider';

export const Title = styled(Typography)``;

Title.displayName = 'Title';

export const Subtitle = styled(Typography)`
  color: ${({theme}) => theme.palette.grey[100]};
  font-size: 24px;
  margin-top: 10px;
`;

Subtitle.displayName = 'Subtitle';

export const Body = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

Body.displayName = 'Body';

export const Description = styled(Typography)`
  margin: 0;
  padding: 20px;
  padding-bottom: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  max-height: ${({$expanded}) => ($expanded ? '500px' : '110px')};
`;

Description.displayName = 'Description';

export const Actions = styled.div`
  width: 100%;
  padding-top: 20px;
  box-shadow: ${({$expanded, theme}) =>
    $expanded ? 'none' : `0px -3px 7px -4px ${theme.palette.grey[300]}`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Actions.displayName = 'Actions';

export const DemoLink = styled.a`
  margin: 0 0 15px;
  text-decoration: none;
`;

DemoLink.displayName = 'DemoLink';

export const DemoLinkText = styled(Typography)`
  margin: 0 0 15px;
  text-decoration: none;
`;

DemoLinkText.displayName = 'DemoLinkText';

export const AccordionButton = styled.div`
  padding: 10px 11px 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({theme}) => theme.palette.secondary.main};
  margin-bottom: -20px;
  &:hover {
    background-color: ${({theme}) => theme.palette.secondary.main};
  }
`;

AccordionButton.displayName = 'AccordionButton';

export const ArrowUp = styled(ArrowUpIcon)`
  ${arrowStyles}
`;

ArrowUp.displayName = 'ArrowUp';

export const ArrowDown = styled(ArrowDownIcon)`
  ${arrowStyles}
`;

ArrowDown.displayName = 'ArrowDown';
