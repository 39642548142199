import styled from 'styled-components';
import {default as MuiPaper} from '@mui/material/Paper';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {theme} from '../../theme';

export const ContactDialog = styled(Dialog)``;

ContactDialog.displayName = 'ContactDialog';

export const Paper = styled(MuiPaper)`
  padding: 65px;
  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: 32px;
    display: flex;
    justify-content: center;
  }
`;

Paper.displayName = 'Paper';

export const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({theme}) => theme.spacing(2)};
  right: ${({theme}) => theme.spacing(2)};
  ${({theme}) => theme.breakpoints.down('sm')} {
    top: ${({theme}) => theme.spacing(5)};
  }
`;

CloseButton.displayName = 'CloseButton';

export const CloseIcon = styled(ClearIcon)``;

CloseIcon.displayName = 'CloseIcon';

export const Heading = styled(Typography)`
  text-align: center;
`;

Heading.displayName = 'Heading';
