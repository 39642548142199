import {createTheme} from '@mui/material/styles';
import {grey} from '@mui/material/colors';

const defaultTheme = createTheme();
const teal = '#30E6F0';
const pink = '#F04FB4';
const darkGrey = '#222222';
const mediumGrey = '#2c353f';
const lightGrey = '#474f59';
const white = grey[100];
const blue = '#337ab7';
const fontFamily = 'Montserrat';

export const theme = createTheme(defaultTheme, {
  palette: {
    primary: {
      main: teal,
    },
    secondary: {
      main: pink,
    },
  },
  typography: {
    h1: {
      color: white,
      fontFamily,
      fontSize: 'calc(2.5em + 2vmin)',
      fontWeight: 700,
      letterSpacing: 3,
      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 45,
      },
    },
    h2: {
      color: white,
      fontFamily,
      fontSize: 35,
      fontWeight: 700,
      letterSpacing: 3,
    },
    h3: {
      color: lightGrey,
      fontFamily,
      fontSize: 30,
      fontWeight: 700,
    },
    h4: {
      color: white,
      fontFamily,
      fontSize: 30,
      fontWeight: 500,
    },
    h5: {
      color: white,
      fontFamily,
      fontSize: 12,
      fontWeight: 700,
    },
    h6: {
      color: white,
      fontFamily,
      fontSize: 60,
      fontWeight: 700,
      lineHeight: 1.1,
    },
    body1: {
      color: mediumGrey,
      fontFamily,
      fontSize: 14,
      fontWeight: 200,
    },
    body2: {
      color: white,
      fontFamily,
      fontSize: 14,
      fontWeight: 200,
    },
    button: {
      color: blue,
      fontFamily,
      fontSize: 12,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face: {
          font-family: ${fontFamily}, 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
        }
      `,
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        textSizeLarge: {
          fontFamily,
          fontSize: 12,
          textDecoration: 'none',
          fontWeight: 700,
        },
        root: {
          border: 'none',
          height: 40,
          width: 200,
          color: white,
          background: 'linear-gradient(to bottom, #30E6F0 0%, #F04FB4 45%)',
          backgroundSize: '1px 200px',
          transition: 'all 0.3s ease 0s',
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          '&:hover': {
            backgroundPosition: '50%',
            boxShadow: 'none',
          },
          '&:focus': {
            outline: 'inherit',
            outlineOffset: 'inherit',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'inherit',
            outlineOffset: 'inherit',
          },
        },
      },
    },
  },
});
